:root {
  --primary-color-old: #3e4a6e;
  --primary-color: #212145;
  --secondary-color: #fdba2b;  
  --third-color: #9C4B96;

  --blue-700: #1a294a;
  --blue-800: #1f1c4f;

  --red-700: #e74c3c;

  --purple-300: rgba(81, 71, 241, 0.15);

  --grey-hover: rgba(62, 74, 110, 0.1);
  --grey-500: #c4c4c4;
  --grey-600: #646464;
  --grey-700: #787878;
  --grey-800: #323232;

  --white-500: #e5e5e5;
  --white-800: #f5f3f3;
  --white-900: #ffffff;

  --total-black: #000000;

  --activated: #24b31d;
  --deactivated: #ff5430;
  --invited: #fdba2b;
  --notInvited: #3485ff;

  --toastify-font-family: 'Roboto', sans-serif;
}
